import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Table from "../../core/pagination/datatable";
import {
  getReasonList,
  getReasonByGuid,
  deleteReason,
  getReasonTypeList,
} from "../../services/MasterApiServices";
import ReasonModal from "../../components/master/modal/ReasonModal";
import { RefreshCw, PlusCircle, RotateCcw, ChevronUp } from "react-feather";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../redux/toggle";
import {
  exportExcel,
  exportPDF,
  HandlePrint,
  renderTooltip,
} from "../../redux/helper";
import {
  exportMasterExcel,
  exportMasterPDF,
  printMasterReport,
} from "../../core/utils/MasterReportUtils";

const Reason = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [reasonData, setReasonData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [updateData, setUpdateData] = useState([]);
  const { togglehead } = useSelector((state) => state.headerToggle);
  const { menuItems } = useSelector((state) => state.amc);
  const [permit, setpermit] = useState();
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };

  // Fetch the reason list when the component mounts
  useEffect(() => {
    handleReasonList();
    setpermit(menuItems?.menuPermissions?.find((e) => e.menu.name == "Reason"));
  }, []);
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const modifiedList = reasonData?.filter((value) =>
    value.reason?.toLowerCase().includes(searchText?.toLowerCase())
  );
  let typeList;
  const handleReasonList = async () => {
    try {
      typeList = (await getReasonTypeList()) || [];
      const reasonList = (await getReasonList()) || [];

      //   if (Array.isArray(response)) {
      //     setReasonTypeData(response);
      //   } else {
      //     setReasonTypeData([]);
      //   }
      // } catch (error) {
      //   console.log(error);
      //   setReasonTypeData([]);
      // }
      console.log(typeList, "liiiiist");

      const mergedData = reasonList?.map((reason) => {
        console.log(reason.type.guid);

        const matchedType = typeList?.find(
          (type) => type.guid === reason.type.guid
        );
        console.log(matchedType, "maaaatch");

        return {
          ...reason,
          type: matchedType,
        };
      });

      setReasonData(mergedData);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(reasonData, "merged data");

  // Handle the click event to edit a reason
  const handleEditClick = async (guid) => {
    try {
      const response = await getReasonByGuid(guid);
      setUpdateData(response.data); // Set the data for editing
      setModalMode("edit"); // Set the modal mode to 'edit'
      setModalOpen(true); // Open the modal
    } catch (error) {
      console.error("Error fetching reason by GUID:", error);
    }
  };

  // Initialize SweetAlert with ReactContent
  const MySwal = withReactContent(Swal);

  // Handle the click event to delete a reason
  const handleDeleteClick = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteReason(id);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The reason has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              handleReasonList(); // Refresh the list
              setSearchText(""); // Clear the search text
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error("Error deleting reason:", error);
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message,
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const columns = [
    {
      title: "Reason",
      dataIndex: "reason",
      sorter: (a, b) => a.reason?.length - b.reason?.length,
    },
    {
      title: "Short Reason",
      dataIndex: "shortReason",
      sorter: (a, b) => a.shortReason?.length - b.shortReason?.length,
    },
    {
      title: "Type",
      dataIndex: "type.name",
      sorter: (a, b) => a.type?.length - b.type?.length,
      render: (_, record) => record?.type?.name,
    },
    ...(permit?.edit || permit?.deletion
      ? [
          {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_, obj) => (
              <div className="action-table-data">
                <div className="edit-delete-action">
                  {permit?.edit && (
                    <Link
                      className="me-2 p-2"
                      to="#"
                      onClick={() => handleEditClick(obj.guid)}
                    >
                      <i data-feather="edit" className="feather-edit"></i>
                    </Link>
                  )}
                  {permit?.deletion && (
                    <Link
                      className="confirm-text p-2"
                      to="#"
                      onClick={() => handleDeleteClick(obj.id)}
                    >
                      <i data-feather="trash-2" className="feather-trash-2"></i>
                    </Link>
                  )}
                </div>
              </div>
            ),
          },
        ]
      : []),
  ];
  const getActiveTabData = () => {
    let fieldMapping = {};

    // Function to create the field mapping between column titles and dataIndex values
    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map title to dataIndex
      });
      return mappings;
    };

    // Generate the field mapping for the current columns
    fieldMapping = createfieldMapping(columns);

    // Return the filtered data, columns, and field mapping
    return { data: modifiedList, columns: columns, fieldMapping };
  };

  function handleNew() {}

    function handleAddReason() {
      if (Array.isArray(typeList) && typeList.length === 0) {
        alert("inside if");
        setModalOpen(false);
        // Trigger SweetAlert if typeList is empty
        Swal.fire({
          title: "Type List Required",
          text: "Please add to the type list before adding a reason.",
          icon: "warning",
          confirmButtonColor: "#00ff00",
          confirmButtonText: "OK",
        });
        return; // Exit the function to prevent further execution
      }

      // Proceed with adding the reason if typeList is not empty
      // Your logic for adding reason goes here
    }
    // if (typeList?.length === 0) {
    //   alert("cant open");
    //   setModalMode("add");
    //   setModalOpen(false);
    // } else {
    //   setModalMode("add");
    //   setModalOpen(true);
    // }
  

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Reason</h4>
                <h6>Manage reasons here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link
                    onClick={() => exportMasterPDF(getActiveTabData, "Reason")}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link
                    onClick={() =>
                      exportMasterExcel(getActiveTabData, "Reason")
                    }
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={() =>
                      printMasterReport(getActiveTabData, "Reason")
                    }
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => handleReasonList()}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            {permit?.add && (
              <div className="page-btn">
                <Link to="#" className="btn btn-added" onClick={() => {
                  setModalMode("add"); // Set the modal mode to 'add'
                  setModalOpen(true); // Open the modal
                }}>
                  <PlusCircle className="me-2" />
                  New
                </Link>
              </div>
            )}
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table columns={columns} dataSource={modifiedList} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Reason Modal */}
      {modalOpen && (
        <ReasonModal
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {
            handleReasonList(); // Refresh the list
            setSearchText(""); // Clear the search text
          }}
        />
      )}
    </div>
  );
};

export default Reason;
