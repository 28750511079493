import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  PlusCircle,
  RotateCcw,
  ChevronUp,
  Smile,
  Calendar,
  Layers,
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import {
  deleteSales,
  getSalesByTransType,
} from "../../services/SalesApiServices";
import SalesInvoiceModal from "../../components/transactions/modal/SalesInvoiceModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setheadertoggle } from "../../redux/toggle";
import { InvSales } from "../../core/json/invSales";
import dayjs from "dayjs";
import Select from "react-select";
import SalesReport1 from "./Report/SalesReport1";
import SalesSupplier from "./Report/SalesSupplier";
import SalesItemWise from "./Report/SalesItemWise";
import { DatePicker, Tabs } from "antd";
import SalesReport1Invoice from "./Report/SalesReport1Invoice";
import SalesSupplierInvoice from "./Report/SalesSupplierInvoice";
import SalesItemWiseInvoice from "./Report/SalesItemWiseInvoice";
import {
  fetchCustomer,
  fetchProduct,
  fetchSettings,
} from "../../redux/userSetting";
import moment from "moment";
import {
  printReport,
  exportExcel,
  exportPDF,
} from "../../core/utils/reportUtils";
import { fetchBranchSettings } from "../../redux/settingbyBranch";
const SalesInvoice = () => {
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("1");
  const [searchText, setSearchText] = useState("");
  const [salesData, setsalesData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [modalMode, setModalMode] = useState("add");
  // const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [itemwiseData, setItemwiseData] = useState([]);
  const [itemwiseAllData, setItemwiseAllData] = useState([]);
  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const MySwal = withReactContent(Swal);
  const {
    productList,
    customerList,
    formatedproductList,
    formatedcustomerList,
  } = useSelector((state) => state.usersetting);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const { menuItems } = useSelector((state) => state.amc);
  const [permit, setpermit] = useState();
  const dispatch = useDispatch();

  const { togglehead } = useSelector((state) => state.headerToggle);
  const renderTooltip = (message) => (
    <Tooltip id="pdf-tooltip">{message}</Tooltip>
  );
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  useEffect(() => {
    setpermit(
      menuItems?.menuPermissions?.find((e) => e.menu.name == "Sales Invoice")
    );
  }, []);
  useEffect(() => {
    dispatch(fetchProduct(branch));
    dispatch(fetchCustomer(branch));
    dispatch(fetchSettings(branch));
    dispatch(fetchBranchSettings(branch.guid));
    handlesalesData();
    handleFilter();
  }, [branch]);

  const handleTabChange = (key) => {
    console.log("Active Tab:", key);
    setActiveTab(key);
  };

  const handlesalesData = async () => {
    try {
      const response = await getSalesByTransType(branch, 2);
      console.log("inside handlesalesdata");
      console.log(response.data, "---------response");
      // setsalesData(Array.isArray(response.data) ? response.data : []);
      const sales = Array.isArray(response.data) ? response.data : [];
      setsalesData(sales);
      setFilteredData(sales);
      let serialCounter = 1;
      const itemwiseData = (sales) => {
        return sales?.invSalesDetails?.map((item, index) => {
          return {
            key: serialCounter,
            slno: serialCounter++,
            date: sales.invdate,
            entrynumber: sales.entrynumber,
            customer: sales.customer?.name || "Unknown Customer",
            itemName: item.product?.name || "Unknown Item",
            quantity: item.quantity,
            rate: item.price,
            total: item.amount,
            discountTotal: item.discAmt,
            tax: item.taxamnt,
            netAmount: item.netAmt,
          };
        });
      };
      const allItemwiseData = sales.flatMap((invoice) => itemwiseData(invoice));
      setItemwiseAllData(allItemwiseData);
      setItemwiseData(allItemwiseData);
    } catch (error) {
      console.error(
        "Failed to fetch sales invoices",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  // const filteredData = salesData
  //   ?.filter((entry) => {
  //     return Object.keys(entry).some((key) => {
  //       return String(entry[key])
  //         .toLowerCase()
  //         .includes(searchText.toLowerCase());
  //     });
  //   })
  //   .sort((a, b) => b.createdAt - a.createdAt);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleEditClick = async (guid) => {
    try {
      console.log("inside edit click");
      console.log(guid, "guid");

      const response = getsalesInvoiceByGuid(guid);
      console.log(response, "response.data");
      setUpdateData(response);
      setModalMode("edit");
      setModalOpen(true);
    } catch (error) {
      console.error(
        "Error fetching sales invoice by GUID",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  function getsalesInvoiceByGuid(guid) {
    console.log(updateData, "updated data");

    const filteredsales = salesData?.filter((sales) => sales.guid === guid);
    console.log(filteredsales[0], "filteredsales[0]");
    return filteredsales[0];
  }
  const handleDeleteClick = (obj) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const objsales = new InvSales();
          objsales.id = obj.id;
          objsales.guid = obj.guid;
          objsales.branch = branch;
          objsales.isDeleted = true;
          objsales.updatedUser = `{${userId?.id}}`;
          objsales.currency = "00000000-0000-0000-0000-000000000000";

          const response = await deleteSales(objsales);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The sales invoice has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              handlesalesData();
              setSearchText("");
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "something went wrong",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error(
            "Error deleting reason:",
            error?.response?.data?.Message || "something went wrong"
          );
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message || "something went wrong",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };
  const handleFromDateChange = (date) => {
    console.log(date?.$d, "from date");
    setFromDate(date);

    setToDate(null); // reset to date when from date changes
  };
  const handleToDateChange = (date) => {
    setToDate(date);
    console.log(date?.$d, "to date");
  };
  function handleFilter() {
    // console.log("Original purchaseData:", salesData);
    // console.log("Search Text:", searchText);
    // console.log("From Date:", fromDate, "To Date:", toDate);
    // console.log("Selected Product:", selectedProduct?.value);
    // console.log("Selected Supplier:", selectedSupplier?.value);

    const filteredData = salesData?.filter((entry) => {
      console.log("Current Entry:", entry);
      const supplierMatch =
        !selectedSupplier || entry.customer?.guid === selectedSupplier?.value;
      const productMatch =
        !selectedProduct ||
        entry?.invSalesDetails?.some(
          (detail) => detail?.product?.guid === selectedProduct?.value
        );
      const fromDateMatch =
        !fromDate || new Date(entry.invdate) >= new Date(fromDate);
      const toDateMatch =
        !toDate || new Date(entry.invdate) <= new Date(toDate);

      const searchTextMatch =
        // entry?.date?.toLowerCase().includes(searchText?.toLowerCase()) ||
        entry?.customer?.name
          ?.toLowerCase()
          .includes(searchText?.toLowerCase()) ||
        entry?.invdate?.toLowerCase().includes(searchText?.toLowerCase()) ||
        entry?.entrynumber?.toLowerCase().includes(searchText?.toLowerCase()) ||
        entry?.netAmt?.toString().includes(searchText);

      return (
        supplierMatch &&
        productMatch &&
        fromDateMatch &&
        toDateMatch &&
        searchTextMatch
      );
    });

    setFilteredData(filteredData);
    let serialCounter = 1;
    const itemwiseData = (filteredData) => {
      return filteredData?.invSalesDetails?.map((item, index) => {
        return {
          key: serialCounter,
          slno: serialCounter++,
          date: filteredData.invdate,
          entrynumber: filteredData.entrynumber,
          customer: filteredData.customer?.name || "Unknown Customer",
          itemName: item.product?.name || "Unknown Item",
          quantity: item.quantity,
          rate: item.price,
          total: item.amount,
          discountTotal: item.discAmt,
          tax: item.taxamnt,
          netAmount: item.netAmt,
        };
      });
    };
    const allItemwiseData = filteredData.flatMap((invoice) =>
      itemwiseData(invoice)
    );
    setItemwiseData(allItemwiseData);

    console.log(itemwiseData, "itemwiseData");
  }
  const handleProductChange = (selectedOption) => {
    console.log("Selected Product:", selectedOption);
    setSelectedProduct(selectedOption);
  };
  const handlesupplierChange = (selectedOption) => {
    console.log("Selected supplier:", selectedOption);
    setSelectedSupplier(selectedOption);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "invdate",
      sorter: (a, b) => (dayjs(a.invdate).isAfter(dayjs(b.invdate)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Invoice",
      dataIndex: "entrynumber",
      sorter: (a, b) => a.entrynumber.localeCompare(b.entrynumber),
    },
    {
      title: "Customer",
      dataIndex: "customerName",
      sorter: (a, b) => a.customerName.localeCompare(b.customerName),
    },
    {
      title: "Reference No",
      dataIndex: "refNo",
      sorter: (a, b) => a.refNo?.length - b.refNo?.length,
    },
    // {
    //   title: "Status",
    //   dataIndex: "salesPaymentType",
    //   render: (salesPaymentType) => {
    //     let badgeClass = "";
    //     let text = "";

    //     switch (salesPaymentType) {
    //       case 0:
    //         text = "Cash";
    //         badgeClass = "bg-lightyellow";
    //         break;
    //       case 1:
    //         text = "Credit";
    //         badgeClass = "bg-lightred";
    //         break;
    //       case 2:
    //         text = "UPI";
    //         badgeClass = "bg-lightgreen";
    //         break;
    //       case 3:
    //         text = "Card";
    //         badgeClass = "bg-lightpurple";
    //         break;
    //       default:
    //         text = "Unknown";
    //         badgeClass = "";
    //     }

    //     return <span className={`badges ${badgeClass}`}>{text}</span>;
    //   },
    //   sorter: (a, b) => a.salesPaymentType.localeCompare(b.salesPaymentType),
    // },
    {
      title: "Status",
      dataIndex: "salesPaymentType",
      render: (salesPaymentType) => {
        let badgeClass = "";
        let text = "";

        switch (salesPaymentType) {
          case 0:
            text = "Cash";
            badgeClass = "bg-lightyellow";
            break;
          case 1:
            text = "Credit";
            badgeClass = "bg-lightred";
            break;
          case 2:
            text = "UPI";
            badgeClass = "bg-lightgreen";
            break;
          case 3:
            text = "Card";
            badgeClass = "bg-lightpurple";
            break;
          default:
            text = "Unknown";
            badgeClass = "";
        }

        return <span className={`badges ${badgeClass}`}>{text}</span>;
      },
      sorter: (a, b) => {
        const paymentLabels = {
          0: "Cash",
          1: "Credit",
          2: "UPI",
          3: "Card",
        };

        const aLabel = paymentLabels[a.salesPaymentType] || "Unknown";
        const bLabel = paymentLabels[b.salesPaymentType] || "Unknown";

        return aLabel.localeCompare(bLabel);
      },
    },

    {
      title: "Delivery Date",
      dataIndex: "deliveryDate", //no dudate
      sorter: (a, b) =>
        dayjs(a.deliveryDate).isAfter(dayjs(b.deliveryDate)) ? 1 : -1,
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Net Amount",
      dataIndex: "netTotal",
      sorter: (a, b) => a.netTotal - b.netTotal,
    },
    ...(permit?.edit || permit?.deletion
      ? [
          ...(permit?.edit || permit?.deletion
            ? [
                {
                  title: "Actions",
                  dataIndex: "actions",
                  key: "actions",
                  render: (_, obj) => (
                    <div className="action-table-data">
                      <div className="edit-delete-action">
                        {permit?.edit && (
                          <Link
                            className="me-2 p-2"
                            to="#"
                            onClick={() => handleEditClick(obj.guid)}
                          >
                            <i data-feather="edit" className="feather-edit"></i>
                          </Link>
                        )}
                        {permit?.deletion && (
                          <Link
                            className="confirm-text p-2"
                            to="#"
                            onClick={() => handleDeleteClick(obj)}
                          >
                            <i
                              data-feather="trash-2"
                              className="feather-trash-2"
                            ></i>
                          </Link>
                        )}
                      </div>
                    </div>
                  ),
                },
              ]
            : []),
        ]
      : []),
  ];
  const Reportcolumns = [
    {
      title: "Sl No",
      dataIndex: "id",
    },
    // {
    //   title: "Date",
    //   dataIndex: "invdate",
    //   sorter: (a, b) => new Date(a.invdate) - new Date(b.invdate),
    // },
    {
      title: "Date",
      dataIndex: "invdate",
      sorter: (a, b) => new Date(a.invdate) - new Date(b.invdate),
      render: (date) => moment(date).format("MM/DD/YYYY"), // Custom format
    },
    {
      title: "Bill NO",
      dataIndex: "entrynumber",
      sorter: (a, b) => a.entrynumber.localeCompare(b.entrynumber),
    },
    {
      title: "Customer Name",
      dataIndex: "customer.name",
      sorter: (a, b) => a.customer?.name?.localeCompare(b.customer?.name),

      render: (_, record) => record?.customer?.name,
    },
    {
      title: "Total",
      dataIndex: "grossTotal",
      sorter: (a, b) => a.grossTotal - b.grossTotal,
    },
    {
      title: "Addditional Amount",
      dataIndex: "additionalAmt",
      sorter: (a, b) => a.additionalAmt - b.additionalAmt,
    },
    {
      title: "Discount",
      dataIndex: "discountTotal",
      sorter: (a, b) => a.discountTotal - b.discountTotal,
    },
    {
      title: "RoundOff",
      dataIndex: "roundOff",
      sorter: (a, b) => a.roundOff - b.roundOff,
    },
    {
      title: "Tax",
      dataIndex: "taxTotal",
      sorter: (a, b) => a.taxTotal - b.taxTotal,
    },
    {
      title: "Net Total",
      dataIndex: "netTotal",
      sorter: (a, b) => a.netTotal - b.netTotal,
    },
  ];
  const supplierColumns = [
    {
      title: "Sl No",
      dataIndex: "id",
    },
    {
      title: "Date",
      dataIndex: "invdate",
      sorter: (a, b) => new Date(a.invdate) - new Date(b.invdate),
      render: (date) => moment(date).format("MM/DD/YYYY"), // Custom format
    },
    {
      title: "Bill NO",
      dataIndex: "entrynumber",
      sorter: (a, b) => a.entrynumber.localeCompare(b.entrynumber),
    },
    {
      title: "Customer Name",
      dataIndex: "customer.name",
      sorter: (a, b) => a.customer?.name?.localeCompare(b.customer?.name),
      render: (_, record) => record?.customer?.name,
    },
    {
      title: "Total",
      dataIndex: "grossTotal",
      sorter: (a, b) => a.grandTotal - b.grandTotal,
    },
    {
      title: "Delivery Date",
      dataIndex: "deliveryDate",
      sorter: (a, b) => new Date(a.deliveryDate) - new Date(b.deliveryDate),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Discount",
      dataIndex: "discountTotal",
      sorter: (a, b) => a.discountTotal - b.discountTotal,
    },
    {
      title: "Tax",
      dataIndex: "taxTotal",
      sorter: (a, b) => a.taxTotal - b.taxTotal,
    },
    {
      title: "Additonal Amount",
      dataIndex: "additionalAmt",
      sorter: (a, b) => a.additionalAmt - b.additionalAmt,
    },
    {
      title: "RoundOff",
      dataIndex: "roundOff",
      sorter: (a, b) => a.roundOff - b.roundOff,
    },

    {
      title: "Net Total",
      dataIndex: "netTotal",

      sorter: (a, b) => a.netTotal - b.netTotal,
    },
  ];
  const Itemwisecolumns = [
    {
      title: "Sl No",
      dataIndex: "slno",
    },
    // {
    //   title: "Date",
    //   dataIndex: "invdate",
    //   sorter: (a, b) => new Date(a.invdate) - new Date(b.invdate),
    // },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => new Date(a.invdate) - new Date(b.invdate),
      render: (date) => moment(date).format("MM/DD/YYYY"), // Custom format
    },
    {
      title: "Bill No",
      dataIndex: "entrynumber",
      sorter: (a, b) => a.entrynumber.localeCompare(b.entrynumber),
    },

    {
      title: "Customer Name",
      dataIndex: "customer",
      sorter: (a, b) => a.customer?.localeCompare(b.customer),
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      sorter: (a, b) => a.itemName?.localeCompare(b.itemName),
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: "Rate",
      dataIndex: "rate",
      sorter: (a, b) => a.rate - b.rate,
    },
    {
      title: "Total",
      dataIndex: "total",
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: "Discount",
      dataIndex: "discountTotal",
      sorter: (a, b) => a.discountTotal - b.discountTotal,
    },

    {
      title: "Tax",
      dataIndex: "tax",
      sorter: (a, b) => a.tax - b.tax,
    },
    {
      title: "Net Total",
      dataIndex: "netAmount",
      sorter: (a, b) => a.netAmount - b.netAmount,
    },
  ];
  const getActiveTabData = () => {
    let fieldMapping = {};

    // Function to create a column mapping (column title to dataIndex)
    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map column title to its dataIndex
      });
      return mappings;
    };
    switch (activeTab) {
      case "1":
        fieldMapping = createfieldMapping(columns);
        console.log("fieldMapping of first table:", fieldMapping);
        return { data: filteredData, columns: columns, fieldMapping };
      case "2":
        fieldMapping = createfieldMapping(Reportcolumns);
        console.log("fieldMapping of second table:", fieldMapping);
        return { data: filteredData, columns: Reportcolumns, fieldMapping };
      case "3":
        fieldMapping = createfieldMapping(supplierColumns);
        console.log("fieldMapping of third table:", fieldMapping);
        return { data: filteredData, columns: supplierColumns, fieldMapping };
      case "4":
        fieldMapping = createfieldMapping(Itemwisecolumns);
        console.log("fieldMapping of fourth table:", fieldMapping);
        return { data: itemwiseData, columns: Itemwisecolumns, fieldMapping };
      default:
        return { data: [], columns: [] };
    }
  };

  // // const itemwiseData = salesData?.filter(item => {
  // //   const customerName = item.customerName.toLowerCase();
  // //   return customerName.includes(searchText.toLowerCase());
  // });
  function handleReset() {
    setFromDate(null);
    setToDate(null);
    setSelectedProduct(null);
    setSelectedSupplier(null);
    setSearchText("");
    setFilteredData(salesData);
    setItemwiseData(itemwiseAllData);
  }
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Sales Invoice</h4>
                <h6>Manage sales here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link onClick={() => exportPDF(getActiveTabData,"Sales invoice")}>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link
                    onClick={() => exportExcel(getActiveTabData,"Sales invoice")}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={() => printReport(getActiveTabData,"Sales invoice")}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => handlesalesData()}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            {permit?.add && (
              <div className="page-btn">
                <Link
                  to="#"
                  className="btn btn-added"
                  onClick={() => {
                    setModalMode("add"); // Set the modal mode to 'add'
                    setModalOpen(true); // Open the modal
                  }}
                >
                  <PlusCircle className="me-2" />
                  New
                </Link>
              </div>
            )}
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  {/* {isFilterVisible && ( */}
                  <div className="input-blocks me-2">
                    <button
                      className="btn btn-filters  h-100 rounded-2"
                      onClick={handleFilter}
                    >
                      <i data-feather="search" className="feather-search" />{" "}
                      Search
                    </button>
                  </div>
                  {/* )} */}

                  <div className="input-blocks">
                    <button
                      className="btn btn-reset  rounded-2 "
                      onClick={handleReset}
                    >
                      <i
                        data-feather="refresh-cw"
                        className="feather-refresh-cw"
                      />{" "}
                      Reset
                    </button>
                  </div>
                </div>
              </div>
              {/* <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              > */}
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks ms-4">
                      <label>From Date</label>
                      <div className="input-groupicon calender-input">
                        <Calendar className="info-img" />
                        <DatePicker
                          className="datetimepicker"
                          id="from-date"
                          placeholderText="From Date"
                          selected={fromDate}
                          value={fromDate}
                          onChange={handleFromDateChange}
                          startDate={fromDate}
                          selectsStart
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks">
                      <label>To Date</label>
                      <div className="input-groupicon calender-input">
                        <Calendar className="info-img" />
                        <DatePicker
                          id="to-date"
                          placeholderText="To Date"
                          value={toDate}
                          onChange={handleToDateChange}
                          minDate={fromDate}
                          startDate={fromDate}
                          selectsEnd
                          disabled={!fromDate}
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks ">
                      <label>Products</label>
                      <div className="input-groupicon calender-input">
                        <Layers className="info-img" />
                        <Select
                          className="img-select"
                          options={formatedproductList}
                          value={selectedProduct}
                          onChange={handleProductChange}
                          classNamePrefix="react-select"
                          placeholder="Products"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks ">
                      <label>Customers</label>
                      <div className="input-groupicon calender-input">
                        <Smile className="info-img" />
                        <Select
                          className="img-select"
                          options={formatedcustomerList}
                          value={selectedSupplier}
                          onChange={handlesupplierChange}
                          classNamePrefix="react-select"
                          placeholder="Customers"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
              <div className="table-responsive">
                <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                  <TabPane tab="Sales Invoice" key="1">
                    <Table columns={columns} dataSource={filteredData} />
                  </TabPane>
                  <TabPane tab="Detailed Report" key="2">
                    <SalesReport1Invoice
                      fromDate={fromDate?.$d}
                      toDate={toDate?.$d}
                      data={filteredData}
                      Reportcolumns={Reportcolumns}
                    />
                  </TabPane>
                  <TabPane tab="Customer" key="3">
                    <SalesSupplierInvoice
                      fromDate={fromDate?.$d}
                      toDate={toDate?.$d}
                      data={filteredData}
                      supplierColumns={supplierColumns}
                    />
                  </TabPane>
                  <TabPane tab="ItemWise" key="4">
                    <SalesItemWiseInvoice
                      Itemwisecolumns={Itemwisecolumns}
                      itemwiseData={itemwiseData}
                    />
                    {/* <Table columns={summaryColumns} dataSource={summaryData} /> */}
                  </TabPane>
                </Tabs>

                {/* <Table columns={columns} dataSource={filteredData} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Reason Modal */}
      {modalOpen && (
        <SalesInvoiceModal
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {
            handlesalesData();
            setSearchText("");
          }}
          modalOpen={modalOpen}
        />
      )}
    </div>
  );
};

export default SalesInvoice;
