import React, { useEffect, useMemo, useRef, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  Download,
  File,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  Archive,
  User,
  Calendar,
  Filter,
} from "feather-icons-react/build/IconComponents";
import Select from "react-select";
import { Table } from "antd";
import { purchaseslist } from "../../../core/json/purchaselistdata";
import { setheadertoggle } from "../../../redux/toggle";
import { useDispatch, useSelector } from "react-redux";
import {
  getConsolidatedReportItemwise,
  getSalesReport,
  getSummReportItemwise,
} from "../../../services/ReportApiService";
import {
  DatatablePagingParameter,
  Filterclass,
} from "../../../core/json/DatatablePagingParameters";
import {
  exportPDF,
  exportExcel,
  HandlePrint,
  renderTooltip,
} from "../../../redux/helper";
import * as XLSX from "xlsx";
import { Checkbox } from "antd";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { DatePicker } from "antd";
// import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import {
  fetchCounter,
  fetchSection,
  fetchUserRole,
} from "../../../redux/userSetting";
import { fetchUser } from "../../../redux/userServices";
import {
  exportreportExcel,
  exportreportPDF,
  printreportReport,
} from "../../../core/utils/MainReportUtils";

import LoadingUtils from "../../../core/utils/LoadingUtils";

const SalesItemSummary = () => {
  const [searchText, setSearchText] = useState("");
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [modifiedList, setModifiedList] = useState();
  const [itemwiseData, setitemwiseData] = useState();
  const [loading, setloading] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [totalcount, settotalcount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const dispatch = useDispatch();
  const { branch } = useSelector((state) => state.branchlist);
  const { togglehead } = useSelector((state) => state.headerToggle);
  const tableRef = useRef(null);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  useEffect(() => {
    handleReportList(currentPage, pageSize);
    handleFilter();
    //handlegetsalesfullReport();
  }, [branch]);

  const handleReportList = async (page, size) => {
    setloading(true);
    const DatatablePagingParameters = new DatatablePagingParameter();
    DatatablePagingParameters.PageNumber = 1;
    DatatablePagingParameters.PageSize = -1;
    const formattedfromDate = dayjs(fromDate).format("YYYY-MM-DD");
    const formattedtoDate = dayjs(toDate).format("YYYY-MM-DD");
    const Filters = new Filterclass();
    Filters.Branch = branch;
    Filters.FromDate = formattedfromDate;
    Filters.ToDate = formattedtoDate;
    Filters.PaymentType = "";
    Filters.Waiter = "";
    Filters.Counter = "";
    Filters.Section = "";
    try {
      const response = await getSummReportItemwise({
        DatatablePagingParameters,
        Filters,
      });
      setitemwiseData(response?.data?.itemWiseSalesReportDetail);
      settotalcount(
        response?.data?.itemWiseSalesReportDetail[0]?.recordsFilteredCount
      );
      setloading(false);
    } catch (error) {
      console.error(
        "Error Fetching Account Type!",
        error.response.data.Message
      );
      setloading(false);
    }
  };
  const updateProduct = (acc, item) => {
    const productName = item.product?.name;
    const groupName = item.product?.group?.name;
    const categoryName = item.product?.category?.name || "Uncategorized";
    const quantity = item.quantity;
    const total = item.total;
    const counter = item.counter;

    let productIndex = acc.findIndex((entry) => entry.products === productName);

    if (productIndex === -1) {
      acc.push({
        key: productName,
        products: productName,
        groupName: groupName,
        category: categoryName,
        quantity: quantity,
        total: total,
        counter: counter,
      });
    } else {
      acc[productIndex].quantity += quantity;
      acc[productIndex].total += total;
    }

    return acc;
  };

  const itemData = useMemo(() => {
    if (!Array.isArray(itemwiseData) || itemwiseData.length === 0) {
      return [];
    }

    return itemwiseData.reduce((acc, item) => updateProduct(acc, item), []);
  }, [itemwiseData]);

  const handleFilter = () => {
    handleReportList(currentPage, pageSize);

    const filteredData = itemData?.filter((value) =>
      Object?.values(value)?.some(
        (val) =>
          typeof val === "string" &&
          val.toLowerCase().includes(searchText?.toLowerCase() || "")
      )
    );

    setModifiedList(filteredData);
  };
  const menuItems = [
    { value: "1", label: "Product", checked: true },
    { value: "2", label: "Group Name", checked: true },
    { value: "3", label: "Category", checked: true },
    { value: "4", label: "Quantity", checked: true },
    { value: "5", label: "Amount", checked: true },
    { value: "6", label: "Counter", checked: false },
  ];

  const [selectedColumns, setSelectedColumns] = useState();
  const columns = [
    {
      title: "Product",
      key: "1",
      dataIndex: "products",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Group Name",
      key: "2",
      dataIndex: "groupName",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Category",
      key: "3",
      dataIndex: "category",
    },
    {
      title: "Quantity",
      key: "4",
      dataIndex: "quantity",
    },
    {
      title: "Amount",
      key: "5",
      dataIndex: "total",
    },
    {
      title: "Counter",
      key: "6",
      dataIndex: "counter",
      render: (text) => <span>{text?.name}</span>,
    },
  ];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  const handleFromDateChange = (date) => {
    setFromDate(date);

    setToDate(null);
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };
  const getActiveTabData = () => {
    let fieldMapping = {};

    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex;
      });
      return mappings;
    };

    fieldMapping = createfieldMapping(columns);

    return {
      data: modifiedList?.length > 0 ? modifiedList : itemData,
      columns: columns,
      fieldMapping,
    };
  };
  function handleReset() {
    setFromDate(null);
    setToDate(null);
    setSearchText("");
    setModifiedList([]);
    handleReportList(currentPage, pageSize);
    //handlegetsalesfullReport();
  }

  useEffect(() => {
    // Set default selected columns based on menuItems' checked status
    const initialSelectedColumns = menuItems
      ?.filter((item) => item.checked)
      .map((item) => columns.find((col) => col.key === item.value));

    setSelectedColumns(initialSelectedColumns);
  }, []);
  console.log(selectedColumns, "qqqqqq");

  const handleCheckboxChange = (columnKey, checked) => {
    if (checked) {
      // Add column if checkbox is checked
      setSelectedColumns((prevColumns) => {
        const updatedColumns = [
          ...prevColumns,
          columns.find((col) => col.key === columnKey),
        ];

        // Sort the array by col.key in ascending order
        return updatedColumns.sort((a, b) => (a.key > b.key ? 1 : -1));
      });
    } else {
      // Remove column if checkbox is unchecked
      setSelectedColumns((prevColumns) =>
        prevColumns?.filter((col) => col.key !== columnKey)
      );
    }
  };

  return (
    <div>
      <LoadingUtils loading={loading} />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Sales Item Summary Report</h4>
              </div>
            </div>

            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <button
                    onClick={() => exportreportPDF(getActiveTabData,'Sales Item Summary Report')}
                    disabled={!loading}
                    className="btn btn-link bg-white rounded-lg border border-silver"
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </button>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <button
                    onClick={() => exportreportExcel(getActiveTabData,'Sales Item Summary Report')}
                    disabled={!loading}
                    className="btn btn-link bg-white rounded-lg border border-silver"
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="excel-icon"
                    />
                  </button>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <button
                    onClick={() => printreportReport(getActiveTabData,'SalesItemSummary Report')}
                    disabled={!loading}
                    className="btn btn-link bg-white rounded-lg border border-silver"
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </button>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <div className="input-blocks me-2">
                    <button
                      className="btn btn-filters rounded-2 h-100 "
                      onClick={() => {
                        setCurrentPage(1);
                        setPageSize(20);
                        handleReportList(currentPage, pageSize);
                        // handlegetsalesReport(currentPage, pageSize);
                      }}
                    >
                      <i data-feather="search" className="feather-search" />{" "}
                      Search
                    </button>
                  </div>

                  <div className="input-blocks">
                    <button
                      className="btn btn-reset  rounded-2 "
                      onClick={handleReset}
                    >
                      <i
                        data-feather="refresh-cw"
                        className="feather-refresh-cw"
                      />{" "}
                      Reset
                    </button>
                  </div>
                </div>
                {/* <div className="search-path">
                  <Link
                    className={`btn btn-filter ${
                      isFilterVisible ? "setclose" : ""
                    }`}
                    onClick={toggleFilterVisibility}
                    id="filter_search"
                  >
                    <Filter className="filter-icon" />
                    <span>
                      <ImageWithBasePath
                        src="assets/img/icons/closes.svg"
                        alt="img"
                      />
                    </span>
                  </Link>
                </div> */}

                <div className="dropdown">
                  <button
                    className="btn  dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <Sliders className="info-img" />
                  </button>
                  <ul className="dropdown-menu p-2">
                    {menuItems?.map((obj, i) => {
                      return (
                        <li key={i}>
                          <Checkbox
                            onChange={(e) =>
                              handleCheckboxChange(obj.value, e.target.checked)
                            }
                            defaultChecked={obj.checked}
                          >
                            <div style={{ color: "black" }}> {obj.label}</div>
                          </Checkbox>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks ms-4">
                      <label>From Date</label>
                      <div className="input-groupicon calender-input">
                        <Calendar className="info-img" />
                        <DatePicker
                          className="datetimepicker"
                          id="from-date"
                          placeholderText="From Date"
                          selected={fromDate}
                          value={fromDate}
                          onChange={handleFromDateChange}
                          startDate={fromDate}
                          selectsStart
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <label>To Date</label>
                      <div className="input-groupicon calender-input">
                        <Calendar className="info-img" />
                        <DatePicker
                          id="to-date"
                          placeholderText="To Date"
                          value={toDate}
                          onChange={handleToDateChange}
                          minDate={fromDate}
                          startDate={fromDate}
                          selectsEnd
                          disabled={!fromDate}
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* /Filter */}
              {selectedColumns && (
                <div className="table-responsive product-list">
                  <Table
                    columns={selectedColumns}
                    dataSource={
                      modifiedList?.length > 0 ? modifiedList : itemData
                    }
                    ref={tableRef}
                    id="table-to-export"
                    pagination={{
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${totalcount} items`,
                      current: currentPage,
                      // pageSize: pageSize,
                      total: modifiedList?.length, // Total records count
                      onChange: (page, size) => {
                        setCurrentPage(page);
                        setPageSize(size);
                        handleReportList(page, size);
                      },
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};
export default SalesItemSummary;
