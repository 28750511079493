import React from "react";
import { Route, Navigate } from "react-router-dom";
import ProductList from "../feature-module/inventory/productlist";
import Dashboard from "../Pages/dasboard/Dashboard";
import AddProduct from "../feature-module/inventory/addproduct";
import BrandList from "../feature-module/inventory/brandlist";
import VariantAttributes from "../feature-module/inventory/variantattributes";
import Warranty from "../feature-module/inventory/warranty";
import PrintBarcode from "../feature-module/inventory/printbarcode";
import Grid from "../feature-module/uiinterface/grid";
import Images from "../feature-module/uiinterface/images";
import Lightboxes from "../feature-module/uiinterface/lightbox";
import Media from "../feature-module/uiinterface/media";
import Modals from "../feature-module/uiinterface/modals";
import Offcanvas from "../feature-module/uiinterface/offcanvas";
import Pagination from "../feature-module/uiinterface/pagination";

import Alert from "../feature-module/uiinterface/alert";
import Accordion from "../feature-module/uiinterface/accordion";
import Avatar from "../feature-module/uiinterface/avatar";
import Badges from "../feature-module/uiinterface/badges";
import Borders from "../feature-module/uiinterface/borders";
import Buttons from "../feature-module/uiinterface/buttons";
import ButtonsGroup from "../feature-module/uiinterface/buttonsgroup";
import Popovers from "../feature-module/uiinterface/popover";

import Breadcrumb from "../feature-module/uiinterface/breadcrumb";
import Cards from "../feature-module/uiinterface/cards";
import Dropdowns from "../feature-module/uiinterface/dropdowns";
import Colors from "../feature-module/uiinterface/colors";
import Carousel from "../feature-module/uiinterface/carousel";
import Spinner from "../feature-module/uiinterface/spinner";
import NavTabs from "../feature-module/uiinterface/navtabs";
import Toasts from "../feature-module/uiinterface/toasts";
import Typography from "../feature-module/uiinterface/typography";
import Video from "../feature-module/uiinterface/video";
import Tooltips from "../feature-module/uiinterface/tooltips";
import DragDrop from "../feature-module/uiinterface/advancedui/dragdrop";
import SweetAlert from "../feature-module/uiinterface/sweetalert";
import Progress from "../feature-module/uiinterface/progress";
import Placeholder from "../feature-module/uiinterface/placeholder";
import Rating from "../feature-module/uiinterface/advancedui/rating";
import TextEditor from "../feature-module/uiinterface/advancedui/texteditor";
import Uiscrollbar from "../feature-module/uiinterface/advancedui/uiscrollbar";
import Stickynote from "../feature-module/uiinterface/advancedui/stickynote";
import Timeline from "../feature-module/uiinterface/advancedui/timeline";
import Apexchart from "../feature-module/uiinterface/charts/apexcharts";
import ChartJs from "../feature-module/uiinterface/charts/chartjs";
import RangeSlides from "../feature-module/uiinterface/rangeslider";
import FontawesomeIcons from "../feature-module/uiinterface/icons/fontawesome";
import FeatherIcons from "../feature-module/uiinterface/icons/feathericon";
import IonicIcons from "../feature-module/uiinterface/icons/ionicicons";
import MaterialIcons from "../feature-module/uiinterface/icons/materialicon";
import PE7Icons from "../feature-module/uiinterface/icons/pe7icons";
import SimplelineIcons from "../feature-module/uiinterface/icons/simplelineicon";
import ThemifyIcons from "../feature-module/uiinterface/icons/themify";
import WeatherIcons from "../feature-module/uiinterface/icons/weathericons";
import TypiconIcons from "../feature-module/uiinterface/icons/typicons";
import FlagIcons from "../feature-module/uiinterface/icons/flagicons";

const routes = all_routes;

import DepartmentGrid from "../feature-module/hrm/departmentgrid";
import DepartmentList from "../feature-module/hrm/departmentlist";
import Designation from "../feature-module/hrm/designation";
import Shift from "../feature-module/hrm/shift";
import AttendanceEmployee from "../feature-module/hrm/attendance-employee";
import ClipBoard from "../feature-module/uiinterface/advancedui/clipboard";
import TablesBasic from "../feature-module/uiinterface/table/tables-basic";
import DataTables from "../feature-module/uiinterface/table/data-tables";
import FormBasicInputs from "../feature-module/uiinterface/forms/formelements/basic-inputs";
import CheckboxRadios from "../feature-module/uiinterface/forms/formelements/checkbox-radios";
import InputGroup from "../feature-module/uiinterface/forms/formelements/input-group";
import GridGutters from "../feature-module/uiinterface/forms/formelements/grid-gutters";
import FormSelect from "../feature-module/uiinterface/forms/formelements/form-select";
import FileUpload from "../feature-module/uiinterface/forms/formelements/fileupload";
import FormMask from "../feature-module/uiinterface/forms/formelements/form-mask";
import FormHorizontal from "../feature-module/uiinterface/forms/formelements/layouts/form-horizontal";
import FormVertical from "../feature-module/uiinterface/forms/formelements/layouts/form-vertical";
import FloatingLabel from "../feature-module/uiinterface/forms/formelements/layouts/floating-label";
import FormValidation from "../feature-module/uiinterface/forms/formelements/layouts/form-validation";
import FormSelect2 from "../feature-module/uiinterface/forms/formelements/layouts/form-select2";
import Ribbon from "../feature-module/uiinterface/advancedui/ribbon";
import Chats from "../feature-module/Application/chat";
import ExpensesList from "../feature-module/FinanceAccounts/expenseslist";
import ExpenseCategory from "../feature-module/FinanceAccounts/expensecategory";
import Calendar from "../feature-module/Application/calendar";
import FormWizard from "../feature-module/uiinterface/forms/formelements/form-wizard";
import ExpiredProduct from "../feature-module/inventory/expiredproduct";
import LowStock from "../feature-module/inventory/lowstock";
import CategoryList from "../feature-module/inventory/categorylist";
import SubCategories from "../feature-module/inventory/subcategories";
import EditProduct from "../feature-module/inventory/editproduct";
import Videocall from "../feature-module/Application/videocall";
import Audiocall from "../feature-module/Application/audiocall";
import Email from "../feature-module/Application/email";
import Callhistory from "../feature-module/Application/callhistory";
import ToDo from "../feature-module/Application/todo";
import QRcode from "../feature-module/inventory/qrcode";
import Appearance from "../feature-module/settings/websitesettings/appearance";
import SocialAuthentication from "../feature-module/settings/websitesettings/socialauthentication";
import LanguageSettings from "../feature-module/settings/websitesettings/languagesettings";
import InvoiceSettings from "../feature-module/settings/appsetting/invoicesettings";
import PrinterSettings from "../feature-module/settings/appsetting/printersettings";
import PosSettings from "../feature-module/settings/websitesettings/possettings";
import CustomFields from "../feature-module/settings/websitesettings/customfields";
import EmailSettings from "../feature-module/settings/systemsettings/emailsettings";
import SmsGateway from "../feature-module/settings/systemsettings/smsgateway";
import OtpSettings from "../feature-module/settings/systemsettings/otpsettings";
import GdprSettings from "../feature-module/settings/systemsettings/gdprsettings";
import PaymentGateway from "../feature-module/settings/financialsettings/paymentgateway";
import BankSetting from "../feature-module/settings/financialsettings/banksetting";
import Suppliers from "../feature-module/people/suppliers";
import StoreList from "../core/modals/peoples/storelist";
import Managestock from "../feature-module/stock/managestock";
import SalesReport from "../feature-module/Reports/salesreport";

import InventoryReport from "../feature-module/Reports/inventoryreport";
import Invoicereport from "../feature-module/Reports/invoicereport";
import SupplierReport from "../feature-module/Reports/supplierreport";
import CustomerReport from "../feature-module/Reports/customerreport";
import ExpenseReport from "../feature-module/Reports/expensereport";
import IncomeReport from "../feature-module/Reports/incomereport";
import TaxReport from "../feature-module/Reports/taxreport";
import ProfitLoss from "../feature-module/Reports/profitloss";
import GeneralSettings from "../feature-module/settings/generalsettings/generalsettings";
import SecuritySettings from "../feature-module/settings/generalsettings/securitysettings";
import Notification from "../feature-module/settings/generalsettings/notification";
import ConnectedApps from "../feature-module/settings/generalsettings/connectedapps";
import SystemSettings from "../feature-module/settings/websitesettings/systemsettings";
import CompanySettings from "../feature-module/settings/websitesettings/companysettings";
import LocalizationSettings from "../feature-module/settings/websitesettings/localizationsettings";
import Prefixes from "../feature-module/settings/websitesettings/prefixes";
import Preference from "../feature-module/settings/websitesettings/preference";
import BanIpaddress from "../feature-module/settings/othersettings/ban-ipaddress";
import StorageSettings from "../feature-module/settings/othersettings/storagesettings";
import Pos from "../feature-module/sales/pos";
import AttendanceAdmin from "../feature-module/hrm/attendanceadmin";
import Payslip from "../feature-module/hrm/payslip";
import Holidays from "../feature-module/hrm/holidays";
import SalesList from "../feature-module/sales/saleslist";
import InvoiceReport from "../feature-module/sales/invoicereport";
import QuotationList from "../feature-module/sales/quotationlist";
import Notes from "../feature-module/Application/notes";
import FileManager from "../feature-module/Application/filemanager";
import Profile from "../feature-module/pages/profile";
import SigninTwo from "../feature-module/pages/login/signinTwo";
import SigninThree from "../feature-module/pages/login/signinThree";
import RegisterTwo from "../feature-module/pages/register/registerTwo";
import Register from "../feature-module/pages/register/register";
import RegisterThree from "../feature-module/pages/register/registerThree";
import Forgotpassword from "../feature-module/pages/forgotpassword/forgotpassword";
import ForgotpasswordTwo from "../feature-module/pages/forgotpassword/forgotpasswordTwo";
import ForgotpasswordThree from "../feature-module/pages/forgotpassword/forgotpasswordThree";
import Resetpassword from "../feature-module/pages/resetpassword/resetpassword";
import ResetpasswordTwo from "../feature-module/pages/resetpassword/resetpasswordTwo";
import ResetpasswordThree from "../feature-module/pages/resetpassword/resetpasswordThree";
import EmailVerification from "../feature-module/pages/emailverification/emailverification";
import EmailverificationTwo from "../feature-module/pages/emailverification/emailverificationTwo";
import EmailverificationThree from "../feature-module/pages/emailverification/emailverificationThree";
import Twostepverification from "../feature-module/pages/twostepverification/twostepverification";
import TwostepverificationTwo from "../feature-module/pages/twostepverification/twostepverificationTwo";
import TwostepverificationThree from "../feature-module/pages/twostepverification/twostepverificationThree";
import Lockscreen from "../feature-module/pages/lockscreen";
import Error404 from "../feature-module/pages/errorpages/error404";
import Error500 from "../feature-module/pages/errorpages/error500";
import Blankpage from "../feature-module/pages/blankpage";
import Comingsoon from "../feature-module/pages/comingsoon";
import Undermaintainence from "../feature-module/pages/undermaintainence";
import Users from "../feature-module/usermanagement/users";
import RolesPermissions from "../feature-module/usermanagement/rolespermissions";
import Permissions from "../feature-module/usermanagement/permissions";
import DeleteAccount from "../feature-module/usermanagement/deleteaccount";
import EmployeesGrid from "../feature-module/hrm/employeesgrid";
import EditEmployee from "../feature-module/hrm/editemployee";
import AddEmployee from "../feature-module/hrm/addemployee";
import LeavesAdmin from "../feature-module/hrm/leavesadmin";
import LeavesEmployee from "../feature-module/hrm/leavesemployee";
import LeaveTypes from "../feature-module/hrm/leavetypes";
import ProductDetail from "../feature-module/inventory/productdetail";
import { Units } from "../feature-module/inventory/units";
import TaxRates from "../feature-module/settings/financialsettings/taxrates";
import CurrencySettings from "../feature-module/settings/financialsettings/currencysettings";
import WareHouses from "../core/modals/peoples/warehouses";
import Coupons from "../feature-module/coupons/coupons";
import { all_routes } from "./all_routes";
import BankSettingGrid from "../feature-module/settings/financialsettings/banksettinggrid";
import PayrollList from "../feature-module/hrm/payroll-list";
import Tax from "../Pages/Master/Tax";
import Product from "../Pages/Master/Product";
import Reason from "../Pages/Master/Reason";
import CouponCode from "../Pages/Master/CouponCode";
import AccountType from "../Pages/Master/AccountType";
import Currency from "../Pages/Master/Currency";
import Type from "../Pages/Master/Type";
import Unit from "../Pages/Master/Unit";
import Promotions from "../Pages/Master/Promotions";
import PromoProducts from "../Pages/Master/PromoProducts";
import Counter from "../Pages/Master/Counter";
import Customer from "../Pages/Master/Customer";
import Category from "../Pages/Master/Category";
import Discount from "../Pages/Master/Discount";
import GroupEntry from "../Pages/Master/GroupEntry";
import KOTPrinter from "../Pages/Master/KOTPrinter";
import ModifierCategory from "../Pages/Master/ModifierCategory";
import Modifier from "../Pages/Master/Modifier";
// import ModifierCategory from "../Pages/Master/ModifierCategory";
import Reasontype from "../Pages/Master/Reasontype";
import Producttype from "../Pages/Master/Producttype";
import Paymentcard from "../Pages/Master/Paymentcard";
import Productboq from "../Pages/Master/Productboq";
import Supplier from "../Pages/Master/Supplier";
import Section from "../Pages/Master/Section";
import SignIn from "../Pages/Login/SignIn";
import SalesDashboard from "../Pages/dasboard/SalesDashboard";
import Company from "../Pages/Administration/Company";
import Branch from "../Pages/Administration/Branch";
import ProductImport from "../Pages/Administration/ProductImport";
import Sales from "../Pages/Report/SalesReport/Sales";
import ItemWiseSale from "../Pages/Report/SalesReport/ItemWiseSale";
import SalesAudit from "../Pages/Report/SalesReport/SalesAudit";
import CancelledSales from "../Pages/Report/SalesReport/CancelledSales";
import CardSales from "../Pages/Report/SalesReport/CardSales";
import DeletedSales from "../Pages/Report/SalesReport/DeletedSales";
import MergedSales from "../Pages/Report/SalesReport/MergedSales";
import RefundedSales from "../Pages/Report/SalesReport/RefundedSales";
import CategoryWise from "../Pages/Report/ProfitReports/CategoryWise";
import DateWiseProfit from "../Pages/Report/ProfitReports/DateWiseProfit";
import GroupWiseProfit from "../Pages/Report/ProfitReports/GroupWiseProfit";
import ItemWiseProfit from "../Pages/Report/ProfitReports/ItemWiseProfit";
import SalesItemSummary from "../Pages/Report/SummaryReports/SalesItemSummary";
import WastageSummary from "../Pages/Report/SummaryReports/WastageSummary";
import DailyCloseReports from "../Pages/Report/DailyCloseReports";
import ProductPriceReports from "../Pages/Report/ProductPriceReports";
import VATReports from "../Pages/Report/VATReports";
import PayinOutReport from "../Pages/Report/PayinOutReport";
import CustomerCredit from "../Pages/Report/CustomerCredit";
import CustomerRepayment from "../Pages/Report/CustomerRepayment";
import ShiftClose from "../Pages/Report/ShiftClose";
import WastageDetail from "../Pages/Report/WastageDetail";
import PreOverSalesReport from "../Pages/Report/PreOverSalesReport";
import AddonSalesReport from "../Pages/Report/AddonSalesReport";
import StockProductReport from "../Pages/Report/StockProductReport";
import StockOverReport from "../Pages/Report/StockOverReport";
import GSTReports from "../Pages/Report/GSTReports";
import WaiterWiseSales from "../Pages/Report/SalesReport/WaiterWiseSales";
import BulkEdit from "../Pages/Administration/BulkEdit";
import UserRole from "../Pages/Administration/UserRole";
import UserAdmin from "../Pages/Administration/User";
import BranchReport from "../Pages/Report/Consolidated Reports/BranchReport";
import ItemsReport from "../Pages/Report/Consolidated Reports/ItemsReport";
import Paymode from "../Pages/Report/Consolidated Reports/Paymode";
import SectionReport from "../Pages/Report/Consolidated Reports/SectionReport";
import PurchaseInvoice from "../Pages/transactions/PurchaseInvoice";
import PurchaseOrder from "../Pages/transactions/PurchaseOrder";
import GoodsReceipt from "../Pages/transactions/GoodsReceipt";
import PurchaseReturn from "../Pages/transactions/PurchaseReturn";

import SalesQuotation from "../Pages/transactions/SalesQuotation";
import DeliveryNote from "../Pages/transactions/DeliveryNote";
import SalesInvoice from "../Pages/transactions/SalesInvoice";
import SalesReturn from "../Pages/transactions/SalesReturn";

import StockAdjustment from "../Pages/transactions/StockAdjustment";
import StockTransfer from "../Pages/transactions/StockTransfer";

import SalesItemWise from "../Pages/transactions/Report/SalesItemWise";
import Production from "../Pages/transactions/Production";
import Construction from "../core/utils/Construction";
import Expired from "../core/utils/Expired";
import HourlySalesReport from "../Pages/Report/SummaryReports/HourlySalesReport";
import Ledger from "../Pages/Accounting/Ledger";
import Receipt from "../components/accounting/RecieptModal";
// import Journal from "../Pages/Accounting/Journal";
import ReceiptMaster from "../Pages/Accounting/ReceiptMaster";
import PaymentMaster from "../Pages/Accounting/PaymentMaster";
import OpeningStock from "../Pages/transactions/OpeningStock";
import ItemQnty from "../Pages/Report/Consolidated Reports/ItemQnty";
import StockIn from "../Pages/Report/SalesReport/Stock Report/StockIn";
import StockOut from "../Pages/Report/SalesReport/Stock Report/StockOut";
import BillwisePayment from "../Pages/Accounting/BillwisePayment";
import JournalEntry from "../Pages/Accounting/JournalEntry";
import BillwiseReceipt from "../Pages/Accounting/BillwiseReceipt";
import LedgerReport from "../Pages/Report/accounting/LedgerReport";
import LedgerGroupReport from "../Pages/Report/accounting/LedgerGroupReport";
import DaybookReport from "../Pages/Report/accounting/DaybookReport";
import OpeningBalance from "../Pages/Accounting/OpeningBalance";
import CashbookReport from "../Pages/Report/accounting/CashbookReport";
import BankbookReport from "../Pages/Report/accounting/BankbookReport";
import StockSummary from "../Pages/Report/SalesReport/Stock Report/StockSummary";
import StockValueReport from "../Pages/Report/SalesReport/Stock Report/StockValueReport";
import CustomerStatement from "../Pages/Report/accounting/CustomerStatement";
import SupplierStatement from "../Pages/Report/accounting/SupplierStatement";
import PandLReport from "../Pages/Report/accounting/PandLReport";

export const publicRoutes = [
  {
    id: 1,
    path: routes.dashboard,
    name: "home",
    element: <Construction />,
    route: Route,
  },
  {
    id: 2,
    path: routes.productlist,
    name: "products",
    element: <ProductList />,
    route: Route,
  },
  {
    id: 3,
    path: routes.addproduct,
    name: "products",
    element: <AddProduct />,
    route: Route,
  },
  {
    id: 4,
    path: routes.branchdashboard,
    name: "branchdashboard",
    element: <Dashboard />,
    route: Route,
  },
  {
    id: 5,
    path: routes.brandlist,
    name: "brant",
    element: <BrandList />,
    route: Route,
  },
  {
    id: 6,
    path: routes.units,
    name: "unit",
    element: <Units />,
    route: Route,
  },
  {
    id: 7,
    path: routes.variantyattributes,
    name: "variantyattributes",
    element: <VariantAttributes />,
    route: Route,
  },
  {
    id: 8,
    path: routes.warranty,
    name: "warranty",
    element: <Warranty />,
    route: Route,
  },
  {
    id: 9,
    path: routes.barcode,
    name: "barcode",
    element: <PrintBarcode />,
    route: Route,
  },
  {
    id: 10,
    path: routes.alerts,
    name: "alert",
    element: <Alert />,
    route: Route,
  },
  {
    id: 11,
    path: routes.grid,
    name: "grid",
    element: <Grid />,
    route: Route,
  },

  {
    id: 12,
    path: routes.accordion,
    name: "accordion",
    element: <Accordion />,
    route: Route,
  },
  {
    id: 13,
    path: routes.avatar,
    name: "avatar",
    element: <Avatar />,
    route: Route,
  },
  {
    id: 14,
    path: routes.images,
    name: "images",
    element: <Images />,
    route: Route,
  },

  {
    id: 15,
    path: routes.badges,
    name: "badges",
    element: <Badges />,
    route: Route,
  },
  {
    id: 16,
    path: routes.lightbox,
    name: "lightbox",
    element: <Lightboxes />,
    route: Route,
  },

  {
    id: 17,
    path: routes.borders,
    name: "borders",
    element: <Borders />,
    route: Route,
  },
  {
    id: 18,
    path: routes.media,
    name: "lightbox",
    element: <Media />,
    route: Route,
  },
  {
    id: 19,
    path: routes.buttons,
    name: "borders",
    element: <Buttons />,
    route: Route,
  },
  {
    id: 20,
    path: routes.modals,
    name: "modals",
    element: <Modals />,
    route: Route,
  },
  {
    id: 21,
    path: routes.offcanvas,
    name: "offcanvas",
    element: <Offcanvas />,
    route: Route,
  },
  {
    id: 22,
    path: routes.pagination,
    name: "offcanvas",
    element: <Pagination />,
    route: Route,
  },
  {
    id: 23,
    path: routes.buttonsgroup,
    name: "buttonsgroup",
    element: <ButtonsGroup />,
    route: Route,
  },
  {
    id: 24,
    path: routes.popover,
    name: "buttonsgroup",
    element: <Popovers />,
    route: Route,
  },
  {
    id: 25,
    path: routes.breadcrumb,
    name: "breadcrumb",
    element: <Breadcrumb />,
    route: Route,
  },
  {
    id: 26,
    path: routes.cards,
    name: "cards",
    element: <Cards />,
    route: Route,
  },
  {
    id: 27,
    path: routes.dropdowns,
    name: "dropdowns",
    element: <Dropdowns />,
    route: Route,
  },
  {
    id: 27,
    path: routes.colors,
    name: "colors",
    element: <Colors />,
    route: Route,
  },
  {
    id: 28,
    path: routes.carousel,
    name: "carousel",
    element: <Carousel />,
    route: Route,
  },
  {
    id: 29,
    path: routes.spinner,
    name: "spinner",
    element: <Spinner />,
    route: Route,
  },
  {
    id: 30,
    path: routes.carousel,
    name: "carousel",
    element: <Carousel />,
    route: Route,
  },
  {
    id: 31,
    path: routes.navtabs,
    name: "navtabs",
    element: <NavTabs />,
    route: Route,
  },
  {
    id: 32,
    path: routes.toasts,
    name: "toasts",
    element: <Toasts />,
    route: Route,
  },
  {
    id: 33,
    path: routes.typography,
    name: "typography",
    element: <Typography />,
    route: Route,
  },
  {
    id: 34,
    path: routes.video,
    name: "video",
    element: <Video />,
    route: Route,
  },
  {
    id: 35,
    path: routes.tooltip,
    name: "tooltip",
    element: <Tooltips />,
    route: Route,
  },
  {
    id: 36,
    path: routes.draganddrop,
    name: "draganddrop",
    element: <DragDrop />,
    route: Route,
  },
  {
    id: 37,
    path: routes.sweetalerts,
    name: "sweetalerts",
    element: <SweetAlert />,
    route: Route,
  },
  {
    id: 38,
    path: routes.progress,
    name: "progress",
    element: <Progress />,
    route: Route,
  },
  {
    id: 38,
    path: routes.departmentgrid,
    name: "departmentgrid",
    element: <DepartmentGrid />,
    route: Route,
  },
  {
    id: 39,
    path: routes.placeholder,
    name: "placeholder",
    element: <Placeholder />,
    route: Route,
  },

  {
    id: 39,
    path: routes.departmentlist,
    name: "departmentlist",
    element: <DepartmentList />,
    route: Route,
  },
  {
    id: 40,
    path: routes.rating,
    name: "rating",
    element: <Rating />,
  },

  {
    id: 40,
    path: routes.designation,
    name: "designation",
    element: <Designation />,
    route: Route,
  },
  {
    id: 41,
    path: routes.texteditor,
    name: "text-editor",
    element: <TextEditor />,
    route: Route,
  },

  {
    id: 41,

    path: routes.shift,
    name: "shift",
    element: <Shift />,
    route: Route,
  },
  {
    id: 42,
    path: routes.counter,
    name: "counter",
    element: <Counter />,
    route: Route,
  },
  {
    id: 43,
    path: routes.attendanceemployee,
    name: "attendanceemployee",
    element: <AttendanceEmployee />,
    route: Route,
  },
  {
    id: 44,
    path: routes.scrollbar,
    name: "scrollbar",
    element: <Uiscrollbar />,
    route: Route,
  },
  {
    id: 45,
    path: routes.clipboard,
    name: "clipboard",
    element: <ClipBoard />,
    route: Route,
  },
  {
    id: 46,
    path: routes.stickynote,
    name: "stickynote",
    element: <Stickynote />,
    route: Route,
  },
  {
    id: 47,
    path: routes.tablebasic,
    name: "tablebasic",
    element: <TablesBasic />,
    route: Route,
  },
  {
    id: 48,
    path: routes.timeline,
    name: "timeline",
    element: <Timeline />,
    route: Route,
  },
  {
    id: 49,
    path: routes.datatable,
    name: "datatable",
    element: <DataTables />,
    route: Route,
  },
  {
    id: 50,
    path: routes.apexchart,
    name: "apex-chart",
    element: <Apexchart />,
    route: Route,
  },

  {
    id: 51,
    path: routes.basicinput,
    name: "formbasicinput",
    element: <FormBasicInputs />,
    route: Route,
  },
  {
    id: 52,
    path: routes.chartjs,
    name: "chart-js",
    element: <ChartJs />,
    route: Route,
  },
  {
    id: 53,
    path: routes.checkboxradio,
    name: "checkboxradio",
    element: <CheckboxRadios />,
    route: Route,
  },
  {
    id: 54,
    path: routes.rangeslider,
    name: "range-slider",
    element: <RangeSlides />,
    route: Route,
  },
  {
    id: 55,
    path: routes.fontawesome,
    name: "fontawesome",
    element: <FontawesomeIcons />,
    route: Route,
  },
  {
    id: 56,
    path: routes.feathericon,
    name: "feathericon",
    element: <FeatherIcons />,
    route: Route,
  },
  {
    id: 57,
    path: routes.ionicicons,
    name: "ionicicons",
    element: <IonicIcons />,
    route: Route,
  },
  {
    id: 58,
    path: routes.materialicons,
    name: "materialicons",
    element: <MaterialIcons />,
    route: Route,
  },
  {
    id: 59,
    path: routes.pe7icons,
    name: "pe7icons",
    element: <PE7Icons />,
    route: Route,
  },
  {
    id: 60,
    path: routes.simpleline,
    name: "simpleline",
    element: <SimplelineIcons />,
    route: Route,
  },
  {
    id: 61,
    path: routes.themifyicons,
    name: "themifyicon",
    element: <ThemifyIcons />,
    route: Route,
  },
  {
    id: 62,
    path: routes.iconweather,
    name: "iconweather",
    element: <WeatherIcons />,
    route: Route,
  },
  {
    id: 63,
    path: routes.typicons,
    name: "typicons",
    element: <TypiconIcons />,
    route: Route,
  },
  {
    id: 64,
    path: routes.flagicons,
    name: "flagicons",
    element: <FlagIcons />,
    route: Route,
  },
  {
    id: 65,
    path: routes.inputgroup,
    name: "inputgroup",
    element: <InputGroup />,
    route: Route,
  },
  {
    id: 66,
    path: routes.ribbon,
    name: "ribbon",
    element: <Ribbon />,
    route: Route,
  },
  {
    id: 67,
    path: routes.chat,
    name: "chat",
    element: <Chats />,
    route: Route,
  },
  {
    id: 68,
    path: routes.gridgutters,
    name: "gridgutters",
    element: <GridGutters />,
    route: Route,
  },
  {
    id: 69,
    path: routes.gridgutters,
    name: "gridgutters",
    element: <GridGutters />,
    route: Route,
  },
  {
    id: 70,
    path: routes.formselect,
    name: "formselect",
    element: <FormSelect />,
    route: Route,
  },
  {
    id: 71,
    path: routes.fileupload,
    name: "fileupload",
    element: <FileUpload />,
    route: Route,
  },
  {
    id: 72,
    path: routes.formmask,
    name: "formmask",
    element: <FormMask />,
    route: Route,
  },
  {
    id: 73,
    path: routes.formhorizontal,
    name: "formhorizontal",
    element: <FormHorizontal />,
    route: Route,
  },
  {
    id: 74,
    path: routes.formvertical,
    name: "formvertical",
    element: <FormVertical />,
    route: Route,
  },
  {
    id: 75,
    path: routes.floatinglabel,
    name: "floatinglabel",
    element: <FloatingLabel />,
    route: Route,
  },
  {
    id: 76,
    path: routes.formvalidation,
    name: "formvalidation",
    element: <FormValidation />,
    route: Route,
  },
  {
    id: 77,
    path: routes.select2,
    name: "select2",
    element: <FormSelect2 />,
    route: Route,
  },
  {
    id: 78,
    path: routes.wizard,
    name: "wizard",
    element: <FormWizard />,
    route: Route,
  },
  {
    id: 79,
    path: routes.expiredproduct,
    name: "expiredproduct",
    element: <ExpiredProduct />,
    route: Route,
  },
  {
    id: 80,
    path: routes.lowstock,
    name: "lowstock",
    element: <LowStock />,
    route: Route,
  },
  {
    id: 81,
    path: routes.categorylist,
    name: "categorylist",
    element: <CategoryList />,
    route: Route,
  },
  {
    id: 82,
    path: routes.expenselist,
    name: "expenselist",
    element: <ExpensesList />,
    route: Route,
  },
  {
    id: 83,
    path: routes.expensecategory,
    name: "expensecategory",
    element: <ExpenseCategory />,
    route: Route,
  },
  {
    id: 84,
    path: routes.calendar,
    name: "calendar",
    element: <Calendar />,
    route: Route,
  },

  {
    id: 85,
    path: routes.subcategories,
    name: "subcategories",
    element: <SubCategories />,
    route: Route,
  },
  {
    id: 86,
    path: routes.editproduct,
    name: "editproduct",
    element: <EditProduct />,
    route: Route,
  },
  {
    id: 87,
    path: routes.videocall,
    name: "videocall",
    element: <Videocall />,
    route: Route,
  },
  {
    id: 88,
    path: routes.audiocall,
    name: "audiocall",
    element: <Audiocall />,
    route: Route,
  },
  {
    id: 89,
    path: routes.email,
    name: "email",
    element: <Email />,
    route: Route,
  },
  {
    id: 90,
    path: routes.callhistory,
    name: "callhistory",
    element: <Callhistory />,
    route: Route,
  },
  {
    id: 91,
    path: routes.todo,
    name: "todo",
    element: <ToDo />,
    route: Route,
  },
  {
    id: 92,
    path: routes.variantattributes,
    name: "variantattributes",
    element: <VariantAttributes />,
    route: Route,
  },
  {
    id: 93,
    path: routes.qrcode,
    name: "qrcode",
    element: <QRcode />,
    route: Route,
  },
  {
    id: 97,
    path: routes.appearance,
    name: "appearance",
    element: <Appearance />,
    route: Route,
  },
  {
    id: 98,
    path: routes.socialauthendication,
    name: "socialauthendication",
    element: <SocialAuthentication />,
    route: Route,
  },
  {
    id: 100,
    path: routes.languagesettings,
    name: "languagesettings",
    element: <LanguageSettings />,
    route: Route,
  },
  {
    id: 101,
    path: routes.invoicesettings,
    name: "invoicesettings",
    element: <InvoiceSettings />,
    route: Route,
  },
  {
    id: 102,
    path: routes.printersettings,
    name: "printersettings",
    element: <PrinterSettings />,
    route: Route,
  },
  {
    id: 103,
    path: routes.possettings,
    name: "possettings",
    element: <PosSettings />,
    route: Route,
  },
  {
    id: 104,
    path: routes.customfields,
    name: "customfields",
    element: <CustomFields />,
    route: Route,
  },
  {
    id: 105,
    path: routes.emailsettings,
    name: "emailsettings",
    element: <EmailSettings />,
    route: Route,
  },
  {
    id: 106,
    path: routes.smssettings,
    name: "smssettings",
    element: <SmsGateway />,
    route: Route,
  },
  {
    id: 107,
    path: routes.otpsettings,
    name: "otpsettings",
    element: <OtpSettings />,
    route: Route,
  },
  {
    id: 108,
    path: routes.gdbrsettings,
    name: "gdbrsettings",
    element: <GdprSettings />,
    route: Route,
  },
  {
    id: 109,
    path: routes.paymentgateway,
    name: "paymentgateway",
    element: <PaymentGateway />,
    route: Route,
  },
  {
    id: 110,
    path: routes.banksettingslist,
    name: "banksettingslist",
    element: <BankSetting />,
    route: Route,
  },

  {
    id: 112,
    path: routes.suppliers,
    name: "suppliers",
    element: <Suppliers />,
    route: Route,
  },
  {
    id: 113,
    path: routes.storelist,
    name: "storelist",
    element: <StoreList />,
    route: Route,
  },
  {
    id: 114,
    path: routes.managestock,
    name: "managestock",
    element: <Managestock />,
    route: Route,
  },
  {
    id: 115,
    path: routes.StockAdjustment,
    name: "stockadjustment",
    element: <StockAdjustment />,
    route: Route,
  },
  {
    id: 116,
    path: routes.StockTransfer,
    name: "stocktransfer",
    element: <StockTransfer />,
    route: Route,
  },
  {
    id: 117,
    path: routes.salesreport,
    name: "salesreport",
    element: <SalesReport />,
    route: Route,
  },

  {
    id: 119,
    path: routes.inventoryreport,
    name: "inventoryreport",
    element: <InventoryReport />,
    route: Route,
  },
  {
    id: 200,
    path: routes.invoicereport,
    name: "invoicereport",
    element: <Invoicereport />,
    route: Route,
  },
  {
    id: 201,
    path: routes.supplierreport,
    name: "supplierreport",
    element: <SupplierReport />,
    route: Route,
  },
  {
    id: 202,
    path: routes.customerreport,
    name: "customerreport",
    element: <CustomerReport />,
    route: Route,
  },
  {
    id: 203,
    path: routes.expensereport,
    name: "expensereport",
    element: <ExpenseReport />,
    route: Route,
  },
  {
    id: 204,
    path: routes.incomereport,
    name: "incomereport",
    element: <IncomeReport />,
    route: Route,
  },
  {
    id: 205,
    path: routes.taxreport,
    name: "taxreport",
    element: <TaxReport />,
    route: Route,
  },
  {
    id: 206,
    path: routes.profitloss,
    name: "profitloss",
    element: <ProfitLoss />,
    route: Route,
  },
  {
    id: 207,
    path: routes.generalsettings,
    name: "generalsettings",
    element: <GeneralSettings />,
    route: Route,
  },
  {
    id: 208,
    path: routes.securitysettings,
    name: "securitysettings",
    element: <SecuritySettings />,
    route: Route,
  },
  {
    id: 209,
    path: routes.notification,
    name: "notification",
    element: <Notification />,
    route: Route,
  },
  {
    id: 210,
    path: routes.connectedapps,
    name: "connectedapps",
    element: <ConnectedApps />,
    route: Route,
  },
  {
    id: 211,
    path: routes.systemsettings,
    name: "systemsettings",
    element: <SystemSettings />,
    route: Route,
  },
  {
    id: 212,
    path: routes.companysettings,
    name: "companysettings",
    element: <CompanySettings />,
    route: Route,
  },
  {
    id: 213,
    path: routes.localizationsettings,
    name: "localizationsettings",
    element: <LocalizationSettings />,
    route: Route,
  },
  {
    id: 214,
    path: routes.prefixes,
    name: "prefixes",
    element: <Prefixes />,
    route: Route,
  },
  {
    id: 215,
    path: routes.preference,
    name: "preference",
    element: <Preference />,
    route: Route,
  },
  {
    id: 216,
    path: routes.banipaddress,
    name: "banipaddress",
    element: <BanIpaddress />,
    route: Route,
  },
  {
    id: 217,
    path: routes.storagesettings,
    name: "storagesettings",
    element: <StorageSettings />,
    route: Route,
  },
  {
    id: 218,
    path: routes.taxrates,
    name: "taxrates",
    element: <TaxRates />,
    route: Route,
  },
  {
    id: 219,
    path: routes.currencysettings,
    name: "currencysettings",
    element: <CurrencySettings />,
    route: Route,
  },
  {
    id: 220,
    path: routes.pos,
    name: "pos",
    element: <Pos />,
    route: Route,
  },
  {
    id: 221,
    path: routes.attendanceadmin,
    name: "attendanceadmin",
    element: <AttendanceAdmin />,
    route: Route,
  },
  {
    id: 222,
    path: routes.payslip,
    name: "payslip",
    element: <Payslip />,
    route: Route,
  },
  {
    id: 223,
    path: routes.saleslist,
    name: "saleslist",
    element: <SalesList />,
    route: Route,
  },
  {
    id: 224,
    path: routes.invoicereport,
    name: "invoicereport",
    element: <InvoiceReport />,
    route: Route,
  },
  {
    id: 225,
    path: routes.holidays,
    name: "holidays",
    element: <Holidays />,
    route: Route,
  },
  {
    id: 227,
    path: routes.quotationlist,
    name: "quotationlist",
    element: <QuotationList />,
    route: Route,
  },
  {
    id: 228,
    path: routes.notes,
    name: "notes",
    element: <Notes />,
    route: Route,
  },
  {
    id: 229,
    path: routes.filemanager,
    name: "filemanager",
    element: <FileManager />,
    route: Route,
  },
  {
    id: 230,
    path: routes.profile,
    name: "profile",
    element: <Profile />,
    route: Route,
  },
  {
    id: 231,
    path: routes.blankpage,
    name: "blankpage",
    element: <Blankpage />,
    route: Route,
  },
  {
    id: 232,
    path: routes.users,
    name: "users",
    element: <Users />,
    route: Route,
  },
  {
    id: 233,
    path: routes.rolespermission,
    name: "rolespermission",
    element: <RolesPermissions />,
    route: Route,
  },
  {
    id: 234,
    path: routes.permissions,
    name: "permissions",
    element: <Permissions />,
    route: Route,
  },
  {
    id: 235,
    path: routes.deleteaccount,
    name: "deleteaccount",
    element: <DeleteAccount />,
    route: Route,
  },
  {
    id: 236,
    path: routes.employeegrid,
    name: "employeegrid",
    element: <EmployeesGrid />,
    route: Route,
  },
  {
    id: 237,
    path: routes.addemployee,
    name: "addemployee",
    element: <AddEmployee />,
    route: Route,
  },
  {
    id: 238,
    path: routes.editemployee,
    name: "editemployee",
    element: <EditEmployee />,
    route: Route,
  },
  {
    id: 239,
    path: routes.leavesadmin,
    name: "leavesadmin",
    element: <LeavesAdmin />,
    route: Route,
  },
  {
    id: 240,
    path: routes.leavesemployee,
    name: "leavesemployee",
    element: <LeavesEmployee />,
    route: Route,
  },
  {
    id: 241,
    path: routes.leavestype,
    name: "leavestype",
    element: <LeaveTypes />,
    route: Route,
  },
  {
    id: 242,
    path: routes.productdetails,
    name: "productdetails",
    element: <ProductDetail />,
    route: Route,
  },
  {
    id: 243,
    path: routes.warehouses,
    name: "warehouses",
    element: <WareHouses />,
    route: Route,
  },
  {
    id: 244,
    path: routes.coupons,
    name: "coupons",
    element: <Coupons />,
    route: Route,
  },
  {
    id: 245,
    path: "*",
    name: "NotFound",
    element: <Navigate to="/" />,
    route: Route,
  },
  {
    id: 246,
    path: "/",
    name: "Root",
    element: <Navigate to="/signin" />,
    route: Route,
  },
  {
    id: 247,
    path: routes.banksettingsgrid,
    name: "banksettingsgrid",
    element: <BankSettingGrid />,
    route: Route,
  },
  {
    id: 248,
    path: routes.payrollList,
    name: "payroll-list",
    element: <PayrollList />,
    route: Route,
  },
  {
    id: 249,
    path: routes.tax,
    name: "tax",
    element: <Tax />,
    route: Route,
  },
  {
    id: 250,
    path: routes.product,
    name: "product",
    element: <Product />,
    route: Route,
  },
  {
    id: 251,
    path: routes.reason,
    name: "reason",
    element: <Reason />,
    route: Route,
  },
  {
    id: 252,
    path: routes.couponcode,
    name: "couponcode",
    element: <CouponCode />,
  },
  {
    id: 253,
    path: routes.customer,
    name: "customer",
    element: <Customer />,
    route: Route,
  },
  {
    id: 254,
    path: routes.accounttype,
    name: "accounttype",
    element: <AccountType />,
    route: Route,
  },
  {
    id: 255,
    path: routes.category,
    name: "category",
    element: <Category />,
    route: Route,
  },
  {
    id: 256,
    path: routes.couponcode,
    name: "couponcode",
    element: <CouponCode />,
    route: Route,
  },
  {
    id: 257,
    path: routes.discount,
    name: "discount",
    element: <Discount />,
    route: Route,
  },
  {
    id: 258,
    path: routes.currency,
    name: "currency",
    element: <Currency />,
    route: Route,
  },
  {
    id: 259,
    path: routes.groupentry,
    name: "groupentry",
    element: <GroupEntry />,
    route: Route,
  },
  {
    id: 259,
    path: routes.kotprinter,
    name: "kotprinter",
    element: <KOTPrinter />,
    route: Route,
  },
  {
    id: 260,
    path: routes.type,
    name: "type",
    element: <Type />,
    route: Route,
  },
  {
    id: 261,
    path: routes.unit,
    name: "unit",
    element: <Unit />,
    route: Route,
  },
  {
    id: 262,
    path: routes.promotions,
    name: "promotions",
    element: <Promotions />,
    route: Route,
  },
  {
    id: 263,
    path: routes.promoproducts,
    name: "promoproducts",
    element: <PromoProducts />,
    route: Route,
  },
  {
    id: 264,
    path: routes.modifiercategory,
    name: "modifiercategory",
    element: <ModifierCategory />,
    route: Route,
  },
  {
    id: 264,
    path: routes.paymentcard,
    name: "paymentcard",
    element: <Paymentcard />,
    route: Route,
  },
  {
    id: 265,
    path: routes.modifier,
    name: "modifier",
    element: <Modifier />,
    route: Route,
  },
  {
    id: 266,
    path: routes.producttype,
    name: "producttype",
    element: <Producttype />,
    route: Route,
  },
  {
    id: 266,
    path: routes.reasontype,
    name: "reasontype",
    element: <Reasontype />,
    route: Route,
  },
  {
    id: 267,
    path: routes.section,
    name: "section",
    element: <Section />,
    route: Route,
  },
  {
    id: 268,
    path: routes.productboq,
    name: "productboq",
    element: <Productboq />,
    route: Route,
  },
  {
    id: 269,
    path: routes.supplier,
    name: "supplier",
    element: <Supplier />,
    route: Route,
  },

  {
    id: 270,
    path: routes.PurchaseInvoice,
    name: "purchaseinvoice",
    element: <PurchaseInvoice />,
    route: Route,
  },
  {
    id: 570,
    path: routes.PurchaseOrder,
    name: "purchaseorder",
    element: <PurchaseOrder />,
    route: Route,
  },
  {
    id: 571,
    path: routes.GoodsReceipt,
    name: "goodsreceipt",
    element: <GoodsReceipt />,
    route: Route,
  },
  {
    id: 572,
    path: routes.PurchaseReturn,
    name: "purchasereturn",
    element: <PurchaseReturn />,
    route: Route,
  },
  {
    id: 271,
    path: routes.company,
    name: "company",
    element: <Company />,
    route: Route,
  },
  {
    id: 271,
    path: routes.branch,
    name: "company",
    element: <Branch />,
    route: Route,
  },
  {
    id: 304,
    path: routes.productimport,
    name: "company",
    element: <Construction />,
    route: Route,
  },
  {
    id: 272,
    path: routes.reportsales,
    name: "reportsales",
    element: <Sales />,
    route: Route,
  },
  {
    id: 303,
    path: routes.itemwisesale,
    name: "itemwisesale",
    element: <ItemWiseSale />,
    route: Route,
  },
  {
    id: 302,
    path: routes.salesaudit,
    name: "salesaudit",
    element: <SalesAudit />,
  },
  {
    id: 273,
    path: routes.bulkedit,
    name: "company",
    element: <Construction />,
    route: Route,
  },
  {
    id: 274,
    path: routes.cancelledsales,
    name: "cancelledsales",
    element: <CancelledSales />,
  },
  {
    path: routes.user,
    name: "company",
    element: <UserAdmin />,
    route: Route,
  },
  {
    id: 275,
    path: routes.itemwisesale,
    name: "itemwisesale",
    element: <ItemWiseSale />,
    route: Route,
  },
  {
    id: 276,
    path: routes.cardsales,
    name: "cardsales",
    element: <CardSales />,
    route: Route,
  },
  {
    id: 277,
    path: routes.deletedsales,
    name: "deletedsales",
    element: <DeletedSales />,
    route: Route,
  },
  {
    id: 278,
    path: routes.mergedsales,
    name: "mergedsales",
    element: <MergedSales />,
    route: Route,
  },
  {
    id: 279,
    path: routes.refundedsales,
    name: "refundedsales",
    element: <RefundedSales />,
    route: Route,
  },
  {
    id: 280,
    path: routes.categorywise,
    name: "categorywise",
    element: <CategoryWise />,
    route: Route,
  },
  {
    id: 281,
    path: routes.datewiseprofit,
    name: "datewiseprofit",
    element: <DateWiseProfit />,
    route: Route,
  },
  {
    id: 282,
    path: routes.groupwiseprofit,
    name: "groupwiseprofit",
    element: <GroupWiseProfit />,
    route: Route,
  },
  {
    id: 283,
    path: routes.itemwiseprofit,
    name: "itemwiseprofit",
    element: <ItemWiseProfit />,
    route: Route,
  },
  {
    id: 284,
    path: routes.salesitemsummary,
    name: "salesitemsummary",
    element: <SalesItemSummary />,
    route: Route,
  },
  {
    id: 285,
    path: routes.wastagesummary,
    name: "wastagesummary",
    element: <WastageSummary />,
    route: Route,
  },
  {
    id: 286,
    path: routes.dailyclosereport,
    name: "dailyclosereport",
    element: <DailyCloseReports />,
    route: Route,
  },
  {
    id: 287,
    path: routes.productpricereport,
    name: "productpricereport",
    element: <ProductPriceReports />,
    route: Route,
  },
  {
    id: 288,
    path: routes.vatreport,
    name: "vatreport",
    element: <VATReports />,
    route: Route,
  },
  {
    id: 289,
    path: routes.payinoutreport,
    name: "payinoutreport",
    element: <PayinOutReport />,
    route: Route,
  },
  {
    id: 290,
    path: routes.customercredit,
    name: "customercredit",
    element: <CustomerCredit />,
    route: Route,
  },
  {
    id: 291,
    path: routes.customerrepayment,
    name: "customerrepayment",
    element: <CustomerRepayment />,
    route: Route,
  },
  {
    id: 292,
    path: routes.shiftclose,
    name: "shiftclose",
    element: <ShiftClose />,
    route: Route,
  },
  {
    id: 293,
    path: routes.wastagedetail,
    name: "wastagedetail",
    element: <WastageDetail />,
    route: Route,
  },
  {
    id: 294,
    path: routes.preoversalereport,
    name: "preoversalereport",
    element: <PreOverSalesReport />,
    route: Route,
  },
  {
    id: 295,
    path: routes.addonsalereport,
    name: "addonsalereport",
    element: <AddonSalesReport />,
    route: Route,
  },
  {
    id: 296,
    path: routes.stockproductreport,
    name: "stockproductreport",
    element: <StockProductReport />,
    route: Route,
  },
  {
    id: 297,
    path: routes.stockoverreport,
    name: "stockoverreport",
    element: <StockOverReport />,
    route: Route,
  },
  {
    id: 298,
    path: routes.gstreport,
    name: "gstreport",
    element: <GSTReports />,
    route: Route,
  },
  {
    id: 299,
    path: routes.waiterwisesales,
    name: "waiterwisesales",
    element: <WaiterWiseSales />,
    route: Route,
  },
  {
    id: 300,
    path: routes.userRole,
    name: "company",
    element: <UserRole />,
    route: Route,
  },
  {
    id: 301,
    path: routes.SalesQuotation,
    name: "salesquotation",
    element: <SalesQuotation />,
    route: Route,
  },
  {
    id: 302,
    path: routes.DeliveryNote,
    name: "deliverynote",
    element: <DeliveryNote />,
    route: Route,
  },
  {
    id: 303,
    path: routes.SalesInvoice,
    name: "salesinvoice",
    element: <SalesInvoice />,
    route: Route,
  },
  {
    id: 304,
    path: routes.SalesReturn,
    name: "salesreturn",
    element: <SalesReturn />,
    route: Route,
  },

  {
    id: 305,
    path: routes.aboutus,
    name: "aboutus",
    element: <Construction />,
    route: Route,
  },
  {
    id: 306,
    path: routes.sectionreport,
    name: "sectionreport",
    element: <SectionReport />,
    route: Route,
  },
  {
    id: 307,
    path: routes.branchreport,
    name: "branchreport",
    element: <BranchReport />,
    route: Route,
  },
  {
    id: 308,
    path: routes.paymodereport,
    name: "paymodereport",
    element: <Paymode />,
    route: Route,
  },
  {
    id: 309,
    path: routes.itemsreport,
    name: "itemsreport",
    element: <ItemsReport />,
    route: Route,
  },
  {
    id: 310,
    path: routes.Production,
    name: "Production",
    element: <Production />,
    route: Route,
  },
  {
    id: 310,
    path: routes.purchasetransaction,
    name: "purchasetransaction",
    element: <Construction />,
    route: Route,
  },
  {
    id: 311,
    path: routes.salesitemsummary,
    name: "salesitemsummary",
    element: <SalesItemWise />,
    route: Route,
  },
  {
    id: 312,
    path: routes.renew,
    name: "renew",
    element: <Expired />,
    route: Route,
  },
  {
    id: 313,
    path: routes.hourlysales,
    name: "hourlysales",
    element: <HourlySalesReport />,
    route: Route,
  },
  {
    id: 314,
    path: routes.ledger,
    name: "ledger",
    element: <Ledger />,
    route: Route,
  },
  {
    id: 315,
    path: routes.receiptmaster,
    name: "receiptmaster",
    element: <ReceiptMaster />,
    route: Route,
  },
  {
    id: 318,
    path: routes.paymentmaster,
    name: "paymentmaster",
    element: <PaymentMaster />,
    route: Route,
  },

  {
    id: 319,
    path: routes.openingstock,
    name: "openingstock",
    element: <OpeningStock />,
    route: Route,
  },
  {
    id: 320,
    path: routes.itemqnty,
    name: "itemqnty",
    element: <ItemQnty />,
    route: Route,
  },
  {
    id: 321,
    path: routes.stockin,
    name: "stockin",
    element: <StockIn />,
    route: Route,
  },
  {
    id: 322,
    path: routes.billwisepayment,
    name: "billwisepayment",
    element: <BillwisePayment />,
    route: Route,
  },
  {
    id: 323,
    path: routes.Stockout,
    name: "Stockout",
    element: <StockOut />,
    route: Route,
  },
  {
    id: 324,
    path: routes.Stocksummary,
    name: "Stocksummary",
    element: <StockSummary />,
    route: Route,
  },
  {
    id: 325,
    path: routes.journal,
    name: "journal",
    element: <JournalEntry />,
    route: Route,
  },
  {
    id: 326,
    path: routes.billwisereceipt,
    name: "billwisereceipt",
    element: <BillwiseReceipt />,
    route: Route,
  },
  {
    id: 327,
    path: routes.ledgerreport,
    name: "ledgerreport",
    element: <LedgerReport />,
    route: Route,
  },
  {
    id: 328,
    path: routes.ledgergroupreport,
    name: "ledgergroupreport",
    element: <LedgerGroupReport />,
    route: Route,
  },
  {
    id: 329,
    path: routes.daybook,
    name: "daybook",
    element: <DaybookReport />,
    route: Route,
  },
  {
    id: 330,
    path: routes.openingbalance,
    name: "openingbalance",
    element: <OpeningBalance />,
    route: Route,
  },
  {
    id: 331,
    path: routes.cashbook,
    name: "cashbook",
    element: <CashbookReport />,
    route: Route,
  },
  {
    id: 332,
    path: routes.bankbook,
    name: "bankbook",
    element: <BankbookReport />,
    route: Route,
  },
  {
    id: 333,
    path: routes.stockvalue,
    name: "stockvalue",
    element: <StockValueReport />,
    route: Route,
  },
  {
    id: 334,
    path: routes.customerstatement,
    name: "customerstatement",
    element: <CustomerStatement />,
    route: Route,
  },
  {
    id: 334,
    path: routes.supplierstatement,
    name: "supplierstatement",
    element: <SupplierStatement />,
    route: Route,
  },
  {
    id: 335,
    path: routes.pandl,
    name: "pandl",
    element: <PandLReport />,
    route: Route,
  },
];
export const posRoutes = [
  {
    id: 1,
    path: routes.pos,
    name: "pos",
    element: <Pos />,
    route: Route,
  },
];

export const pagesRoute = [
  {
    id: 1,
    path: routes.signin,
    name: "signin",
    element: <SignIn />,
    route: Route,
  },
  {
    id: 2,
    path: routes.signintwo,
    name: "signintwo",
    element: <SigninTwo />,
    route: Route,
  },
  {
    id: 3,
    path: routes.signinthree,
    name: "signinthree",
    element: <SigninThree />,
    route: Route,
  },
  {
    id: 4,
    path: routes.register,
    name: "register",
    element: <Register />,
    route: Route,
  },
  {
    id: 5,
    path: routes.registerTwo,
    name: "registerTwo",
    element: <RegisterTwo />,
    route: Route,
  },
  {
    id: 6,
    path: routes.registerThree,
    name: "registerThree",
    element: <RegisterThree />,
    route: Route,
  },
  {
    id: 7,
    path: routes.forgotPassword,
    name: "forgotPassword",
    element: <Forgotpassword />,
    route: Route,
  },
  {
    id: 7,
    path: routes.forgotPasswordTwo,
    name: "forgotPasswordTwo",
    element: <ForgotpasswordTwo />,
    route: Route,
  },
  {
    id: 8,
    path: routes.forgotPasswordThree,
    name: "forgotPasswordThree",
    element: <ForgotpasswordThree />,
    route: Route,
  },
  {
    id: 9,
    path: routes.resetpassword,
    name: "resetpassword",
    element: <Resetpassword />,
    route: Route,
  },
  {
    id: 10,
    path: routes.resetpasswordTwo,
    name: "resetpasswordTwo",
    element: <ResetpasswordTwo />,
    route: Route,
  },
  {
    id: 11,
    path: routes.resetpasswordThree,
    name: "resetpasswordThree",
    element: <ResetpasswordThree />,
    route: Route,
  },
  {
    id: 12,
    path: routes.emailverification,
    name: "emailverification",
    element: <EmailVerification />,
    route: Route,
  },
  {
    id: 12,
    path: routes.emailverificationTwo,
    name: "emailverificationTwo",
    element: <EmailverificationTwo />,
    route: Route,
  },
  {
    id: 13,
    path: routes.emailverificationThree,
    name: "emailverificationThree",
    element: <EmailverificationThree />,
    route: Route,
  },
  {
    id: 14,
    path: routes.twostepverification,
    name: "twostepverification",
    element: <Twostepverification />,
    route: Route,
  },
  {
    id: 15,
    path: routes.twostepverificationTwo,
    name: "twostepverificationTwo",
    element: <TwostepverificationTwo />,
    route: Route,
  },
  {
    id: 16,
    path: routes.twostepverificationThree,
    name: "twostepverificationThree",
    element: <TwostepverificationThree />,
    route: Route,
  },
  {
    id: 17,
    path: routes.lockscreen,
    name: "lockscreen",
    element: <Lockscreen />,
    route: Route,
  },
  {
    id: 18,
    path: routes.error404,
    name: "error404",
    element: <Error404 />,
    route: Route,
  },
  {
    id: 19,
    path: routes.error500,
    name: "error500",
    element: <Error500 />,
    route: Route,
  },
  {
    id: 20,
    path: routes.comingsoon,
    name: "comingsoon",
    element: <Comingsoon />,
    route: Route,
  },
  {
    id: 21,
    path: routes.undermaintenance,
    name: "undermaintenance",
    element: <Undermaintainence />,
    route: Route,
  },
];
